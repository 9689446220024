<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <div class="title">
          <span>03</span>
          <h3>创新凯达</h3>
          <p>Innovation Policy</p>
        </div>
        <p><b class="red">科技创新</b> — 凯达云数字化管理平台</p>
        <p>
          凯达公司积极打造国内领先、省内唯一的“凯达云”数字化管理平台，通过为高素质人才搭建智能化、高科技平台，提升团队自豪感和荣誉感，通过平台、项目、人才一体化运作，做到“事业引人、平台留人、专业育人、科学用人”，用高科技含量平台为优秀人才提供发挥用武之地的舞台，做到人尽其才、才尽其用。
        </p>
        <p><b class="red">制度创新</b> — 人才荣誉和培养晋升机制</p>
        <p>
          凯达公司提供横向专业发展和纵向管理晋升两条职业发展路径，遵循德才兼备、业绩为重的原则，坚持严管和厚爱相结合，通过建立科学、高效、激励性强的荣誉制度和考评、激励、培养、晋升机制，健全以“能力、业绩、创新、质量、实效、贡献”为导向的人才评价体系，建立多层次、多形式、开放性的培训体系。保证晋升规划的合理性、公正性、公平性，让每个凯达人找到自己的职业发展方向。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: { Crumb, Banner },
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner3.jpg"),
    };
  },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .content {
      color: #666;
      .title {
        background: url(../../assets/images/bg-title.png) no-repeat 0 0;
        width: 255px;
        height: 50px;
        color: #fff;
        margin-bottom: 20px;
        padding: 15px 20px;
        span {
          display: block;
          float: left;
          width: 50px;
          height: 50px;
          font-size: 24px;
          color: #eb2737;
          text-align: center;
          font-weight: bold;
          line-height: 50px;
          margin-right: 10px;
        }
        h3 {
          font-size: 20px;
          line-height: 30px;
          font-weight: normal;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      p {
        margin-bottom: 1em;
      }
      .red {
        color: #eb2737;
      }
      .align-right {
        text-align: right;
      }
    }
  }
}
</style>
